@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '../lib/components/video-js/video-js.scss';

@font-face {
  font-family: 'IQOSW04-Bold';
  src: url('/assets/fonts/iqos/IQOSW04-Bold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'IQOSW04-Regular';
  src: url('/assets/fonts/iqos/IQOSW04-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

.grecaptcha-badge {
  bottom: 60px;
  // visibility: hidden;
}