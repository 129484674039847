$primary-foreground-color: #fff; // #fff default

$primary-background-color: #000000; // #2B333F default

$center-big-play-button: true; // true default

.video-js {
	font-size: 13px;
	color: $primary-foreground-color;
	// border-radius: 8px;

	@media (max-width: 768px) {
		font-size: 11px;
	}
}

.vjs-tech {
	// border-radius: 8px;
}

.video-js .vjs-amild-skin {
	min-height: 100%;
	min-width: 100%;
	position: absolute;
}

.vjs-amild-skin .vjs-big-play-button {
	position: absolute;
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;
	border-radius: 15px !important;
	background: var(--cta_1) !important;
	color: var(--cta_2) !important;
	border: none !important;
	box-shadow: 0 0 70px rgba(0, 0, 0, 0.7) !important;
	width: 48px !important;
	height: 48px !important;
	font-size: 30px;
	line-height: 48px;

	@media (min-width: 768px) {
		width: 56px !important;
		height: 56px !important;
		line-height: 56px;
	}
}

/* small square button skin */
.vjs-amild-skin-small-square .vjs-big-play-button {
	position: absolute;
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;
	border-radius: 15px !important;
	background: var(--cta_1) !important;
	color: var(--cta_2) !important;
	border: none !important;
	box-shadow: 0 0 70px rgba(0, 0, 0, 0.7) !important;
	width: 48px !important;
	height: 48px !important;
	font-size: 30px !important;
	line-height: 48px !important;

	@media (min-width: 768px) {
		width: 56px !important;
		height: 56px !important;
		line-height: 56px !important;
	}
}

/* button with blur bg */
.vjs-amild-skin-blur .vjs-big-play-button {
	width: 56px;
	height: 56px;
	line-height: 56px;
	font-size: 25px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	background: fade-out($color: #000000, $amount: 0.5) !important;
	border: none !important;
	backdrop-filter: blur(5px);
	box-shadow: none !important;
	border-radius: 50%;
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		width: 38px;
		height: 38px;
		background: #ed1c24;
		// z-index: 1;
	}
}

.vjs-amild-skin.white .vjs-big-play-button {
	background: rgba(255, 255, 255, 0.5) !important;
}

#videoDetailBM .vjs-amild-skin.white .vjs-big-play-button {
	background: rgba(255, 255, 255, 0.5) !important;
}

.vjs-amild-skin .vjs-big-play-button .vjs-icon-placeholder:before {
	color: var(--cta_2) !important;
	height: auto !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

//  .vjs-play-control :before, .video-js .vjs-big-play-button .vjs-icon-placeholder:before 

.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
	/* IE8 - has no alpha support */
	background-color: $primary-background-color;
	/* Opacity: 1.0 = 100%, 0.0 = 0% */
	background-color: rgba($primary-background-color, 0.9);
}

// Make a slightly lighter version of the main background
// for the slider background.
$slider-bg-color: lighten(red, 90%);

/* Slider - used for Volume bar and Progress bar */
.video-js .vjs-slider {
	display: block;
	background-color: $slider-bg-color;
	background-color: rgba($slider-bg-color, 0.9);
}

.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
	background: $primary-foreground-color;
}

.vjs-poster {
	// border-radius: 8px;
}

/* The main progress bar also has a bar that shows how much has been loaded. */
.video-js .vjs-load-progress {
	display: none;
	/* For IE8 we'll lighten the color */
	background: ligthen($slider-bg-color, 80%);
	/* Otherwise we'll rely on stacked opacities */
	background: rgba($slider-bg-color, 0.8);
}

/* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */
.video-js .vjs-load-progress div {
	/* For IE8 we'll lighten the color */
	background: ligthen($slider-bg-color, 80%);
	/* Otherwise we'll rely on stacked opacities */
	background: rgba($slider-bg-color, 0.9);
}

.video-js .vjs-progress-control.vjs-control {
	display: none;
}

.video-js .vjs-fullscreen-control {
	margin-left: auto !important;
}

.video-js.vjs-amild-skin .vjs-remaining-time.vjs-time-control.vjs-control {
	// border-radius: 8px;
	background: red;
	color: #fff;
	letter-spacing: 1px;

	* {
		font-family: 'NeulandGrotesk' !important;
	}
}

// .duration-label {
// 	position: absolute;
// 	right: 24px;
// 	bottom: 12px;
// 	background: #000000;
// 	color: #fff;
// 	font-family: Arial, Helvetica, sans-serif;
// 	border-radius: 2px;
// 	padding: 4px;
// 	font-size: 13px;
// 	line-height: 17px;
// 	text-align: center;
// 	letter-spacing: 0.03em;
// }

.video-js .vjs-time-control {
	display: block !important;
}

.video-js .vjs-remaining-time {
	display: none !important;
}

.video-js.vjs-amild-skin .vjs-picture-in-picture-control.vjs-control.vjs-button {
	display: none;
}

// Hide if controls are disabled, the video is playing, or native controls are used.
.vjs-controls-disabled .vjs-big-play-button,
.vjs-has-started .vjs-big-play-button,
.vjs-using-native-controls .vjs-amild-skin-blur .vjs-big-play-button,
.vjs-error .vjs-big-play-button {
	display: none;
}

// .video-js:hover .vjs-big-play-button {
//   background-color: rgba(0, 0, 0, 0.95);
//   border-color: #647733;
// }

.duration-label {
	position: absolute;
	right: 24px;
	bottom: 12px;
	background: #000000;
	color: #fff;
	font-family: Georgia, serif;
	border-radius: 2px;
	padding: 4px;
	font-size: 13px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.03em;
	z-index: 10;
}


video::-webkit-media-controls-timeline {
	display: none;
}